.l-main-footer {
	background-color: $gray-lighter;
	padding-top: $ws*2;
	margin-top: $ws*2;

	.l-main-footer--logos {
		background-color: #fff;
		margin-top: $ws*2;
		padding: $ws 0;
		@include clearfix;

		.creativecommons {
			font-size: $font-size-small;
			float: left;
			margin-top: 8px;
			color: $gray-light;
			display: inline-block;
			span {
				display: block;
				margin-top: 5px;
			}
		}

		.privacy {
			font-size: $font-size-small;
			display: block;
			color: $gray-light;
			clear: both;
			text-decoration: underline;
		}

		.o-logo-credits--container {
			float: right;
			margin-top: 10px;

			.o-logo-credits {
				display: inline-block;
				margin: 0 $ws;
				img {
					max-height: 70px;
					max-width: 100%;
					width: auto;
				}
			}
		}

		// XS ONLY
		@media (max-width: $screen-xs-max) {
			.creativecommons {
				display: block;
				float: none;
			}
			.o-logo-credits--container {
				float: none;
				margin-top: $ws;

				.o-logo-credits img {
					max-height: 40px;
				}
			}
		}
	}
}