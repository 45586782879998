// Grid system
// -----------

.main {
	@include make-sm-column($main-sm-columns);
	.sidebar-primary & {
		@include make-sm-column($main-sm-columns - $sidebar-sm-columns);
	}
}
.sidebar {
	@include make-sm-column($sidebar-sm-columns);
}


// 15 COLUMNS GRID
// ---------------

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
	position: relative;
	min-height: 1px;
	padding-right: 10px;
	padding-left: 10px;
}
.col-xs-15 {
	width: 20%;
	float: left;
}
@media (min-width: $screen-sm-min) {
	.col-sm-15 {
		width: 20%;
		float: left;
	}
}
@media (min-width: $screen-md-min) {
	.col-md-15 {
		width: 20%;
		float: left;
	}
}
@media (min-width: $screen-lg-min) {
	.col-lg-15 {
		width: 20%;
		float: left;
	}
}
