.single-recensioni {

	.m-content-single-post {
		max-width: none;

		header {
			max-width: 720px;
			margin-left: auto;
			margin-right: auto;
		}

		.o-content-text {
			max-width: none !important;
		}
	}
}