.o-meta-avatar--container {
	display: inline-block;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	position: relative;
	border: 1px solid $gray-lighter;
	text-align: center;
	overflow: hidden;

	.o-meta-avatar, .avatar {
		@extend .u-center-h-v;
	}

	&.is-big {
		width: 150px;
		height: 150px;

		.o-meta-avatar, .avatar {
			max-width: 150px;
			max-height: 150px;
		}
	}

	&.is-small {
		vertical-align: middle;
		width: 24px;
		height: 24px;
		margin-right: 4px;
		z-index: 100;

		.o-meta-avatar, .avatar {
			max-width: 24px;
			max-height: 24px;
		}
	}

	&.is-normal {
		height: 80px;
		width: 80px;
		margin-bottom: $ws/2;

		.o-meta-avatar, .avatar {
			max-width: 80px;
			max-height: 80px;
		}
	}
}

.o-meta-author {
	margin: 0;
	color: $gray-light;

	a {
		color: $gray-light;
	}
}

.o-meta-featured-img {
	margin: $ws*2 auto $ws auto;
}

.o-meta-details--date {
	color: $gray-light;

	&:before {
		content: 'f';
		font-family: sitosophia;
		vertical-align: middle;
		margin-right: 4px;
	}
}

.o-meta-details--comments {
	color: $gray-light;

	&:before {
		content: 'e';
		font-family: sitosophia;
		vertical-align: middle;
		margin-right: 4px;
	}

	a {
		color: $gray-light;
	}
}
