.m-social-share {
	min-height: 25px;

	.fb-like {
		vertical-align: super;
		margin-right: $ws;
	}

	.twitter-share-button {
		margin-right: $ws;
	}
}