.m-footer-area {

	.twitter-follow-button {
		display: block;
		margin: $ws 0;
	}

	.m-search-form {
		float: none !important;
		text-align: right;
		margin-bottom: $ws;

		// XS e SM
		@media (max-width: $screen-sm-max) {
			text-align: center;
			margin-right: 0;
		}
	}
}