.post-type-archive-archivio {

	.m-filters-block {
		background-color: transparent;
		height: auto;
		@include clearfix;
		margin-bottom: $ws;

		.o-link-filter {
			font-size: $font-size-base;
			font-weight: 700;
			margin-bottom: $ws;

			a {
				color: $gray-light;
			}

			// XS ONLY
			@media (max-width: $screen-xs-max) {
				padding: 0 !important;
				width: 100%;
				text-align: center;
				float: left;
			}
		}
	}
}