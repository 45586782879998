.comment-list {
	@extend .list-unstyled;
}
.comment-list ol {
	list-style: none;
}
.comment-form p {
	@extend .form-group;
}
.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea {
	@extend .form-control;
}
.comment-form input[type="submit"] {
	@extend .btn;
	@extend .btn-primary;
}
.bypostauthor {}