// TUTTI
// -----
.m-content {
	border: 1px solid #ccc;
	padding: $ws $ws*2;
	text-align: center;
	background-color: #fdfdfd;
	position: relative;

	header {
		height: 127px;
		overflow: hidden;
		position: relative;
		margin-bottom: $ws;
		position: relative;
		z-index: 100;

		.m-content--header-content {
			max-height: 127px;
			overflow: hidden;
			@extend .u-center-v;
			width: 100%;
		}

		.o-title-post {
			font-size: 24px;
			line-height: 1.25;
			max-height: 90px;
			overflow: hidden;

			a:hover, a:focus {
				color: $gray;
				text-decoration: none;
			}
		}

		.o-link-category {
			margin-top: $ws/2;

			a {
				border-top: 1px solid $gray-light;
				padding-top: $ws/2;
				color: #000;
			}
		}
	}

	.o-meta-author {
		font-weight: 300;
		position: relative;
		z-index: 100;
		color: #000;

		a {
			color: #000;
		}
	}

	.o-meta-details {
		font-weight: 300;
		margin-top: $ws*2;
		font-size: $font-size-small;
		@include clearfix();
		position: relative;
		z-index: 100;

		.o-meta-details--date {
			float: left;
			color: #000;
		}
		.o-meta-details--comments {
			float: right;
			color: #000;
		}
	}

	.m-content--bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		@include opacity(.15);
		z-index: 10;
	}

	// AUTORE
	// ------
	&.is-author {

		header {
			height: 200px;
			margin-bottom: 0;

			.m-content--header-content {
				max-height: 200px;
			}

			.o-title-post {
				max-height: 60px;
			}

			.o-link-category {
				margin-top: $ws/2;

				span {
					border-top: 1px solid $gray-lighter;
					padding-top: $ws/2;
				}
			}
		}
	}
}


// SINGOLO POST
// ------------
.m-content-single-post {
	max-width: 720px;
	margin: 0 auto;

	header {
		max-width: 80%;
		margin: 0 auto $ws*2 auto;
		padding-bottom: $ws;
		text-align: center;
		border-bottom: 1px solid $gray-lighter;

		.o-title-post {
			line-height: 1.25;
			font-size: 32px;

			@media (min-width: $screen-sm-min) {
				font-size: 46px;
			}
		}

		.o-link-category {
			margin-top: $ws/2;
			margin-bottom: $ws;

			a {
				border-top: 1px solid $gray-lighter;
				padding-top: $ws/2;
				color: $red;
			}
		}

		.o-meta-author {
			a {
				text-decoration: underline;
			}
		}

		.o-meta-details {
			margin: $ws 0;
			font-size: $font-size-small;
			@include clearfix();

			.o-meta-details--date {
				margin-right: $ws;
			}
			.o-meta-details--comments {
				margin-left: $ws;
			}
		}

		.o-link-tags {
			margin-top: $ws/2;
		}
	}

	.embed-responsive {
		margin-bottom: $ws*2;
	}
}
