// ABSOLUTE CENTERING
// ------------------

.u-center-v {
	position: absolute;
	top: 50%;
	@include translate(0, -50%);
}

.u-center-h {
	position: absolute;
	left: 50%;
	@include translate(-50%, 0);
}

.u-center-h-v {
	position: absolute;
	top: 50%;
	left: 50%;
	@include translate(-50%, -50%);
}