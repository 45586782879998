.o-list-sep {
	font-size: $font-size-large;
	font-weight: bold;
}

.o-list-social {
	text-align: right;
	margin: $ws 0;

	.o-link-social {
		margin-right: $ws/2;
		&:last-child {
			margin-right: 0;
		}
	}

	// XS e SM
	@media (max-width: $screen-sm-max) {
		text-align: center;
	}
}