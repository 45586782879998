.o-btn-outline {
	border: 2px solid $gray-light;
	color: $gray-light;
	background-color: transparent;
	@include transition(all .15s);

	&:hover, &:focus {
		color: $red;
		border-color: $red;
	}
}

.o-btn-goto {
	position: relative;
	padding-right: 35px;

	&:after {
		position: absolute;
		right: 0px;
		top: -1px;
		content: 'c';
		font-family: sitosophia;
		font-size: 35px;
	}
}