.o-content-text {
	font-size: $font-size-large;
	line-height: 1.65;
	font-weight: 300;

	// XS ONLY
	@media (max-width: $screen-xs-max) {
		font-size: 16px;
	}

	p {
		margin-bottom: 1.5em;
	}

	iframe, embed {
		max-width: 100%;
	}
}

.o-content-aside {
	font-size: $font-size-small;
}