// POST
// ----
.o-title-post {
	font-family: $font-family-serif;
}

// PAGE
// -----
.o-title-page {
	font-family: $font-family-serif;
}