.m-page-header {
	border: 0;
	margin: 0 0 $ws*2 0;
	padding: 0;
	text-align: center;

	.o-meta-avatar, .avatar {
		margin-bottom: $ws/2;
	}

	.o-title-page {
		font-size: 46px;

		// XS ONLY
		@media (max-width: $screen-xs-max) {
			font-size: 36px;
		}
	}

	.o-link-category {
		margin-top: $ws/2;

		a {
			border-top: 1px solid $gray-lighter;
			padding-top: $ws/2;
		}
	}

	.o-content-text {
		max-width: 720px;
		margin: $ws auto;

		&.is-author-desc {
			text-align: left;
		}
	}
}
