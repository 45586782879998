// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
	margin-left: 0;
	margin-right: 0;
	max-width: 100%;
}
.aligncenter {
	display: block;
	margin: ($line-height-computed) auto;
}
.alignleft,
.alignright {
	margin-bottom: ($line-height-computed);
}
@media (min-width: $screen-sm-min) {
	// Only float if not on an extra small device
	.alignleft {
		float: left;
		margin-right: ($line-height-computed);
	}
	.alignright {
		float: right;
		margin-left: ($line-height-computed);
	}
}

// Captions
.wp-caption {
	@extend .thumbnail;
}
.wp-caption-text {
	// @extend .thumbnail;
	@extend .caption;
}

// Text meant only for screen readers
.screen-reader-text {
	@extend .sr-only;
	@extend .sr-only-focusable;
}

// Sticky posts
.sticky {}

// Gallery
.gallery-caption {
	margin-top: -$ws;
	margin-bottom: $ws;
}