.o-link-category {
	a, span {
		display: inline-block;
		font-style: italic;
		color: $gray;
	}
}

.o-link-tags {
	font-size: 12px;
	a {
		color: $gray-light;
	}
}

.o-link-filter {
	font-family: $font-family-serif;
	padding: 0 $ws !important;
	cursor: pointer;
	color: #fff;
	font-size: 14px;

	&:hover {
		color: $text-color;
	}

	&.is-title {
		cursor: default;
		font-style: italic;
		font-size: $font-size-large;
		display: none;
	}

	&.is-selected {
		color: $text-color;
	}

	// XS ONLY
	@media (max-width: $screen-xs-max) {
		padding: 0 !important;
		width: 33%;
		text-align: center;
		float: left;
		margin: $ws/2 0;
	}

	// SM - MD - LG
	@media (min-width: $screen-sm-min) {
		font-size: 16px;

		&.is-title {
			display: inline-block;
		}
	}

	// MD - LG
	@media (min-width: $screen-md-min) {
		font-size: 22px;
	}
}

.o-link-autore-recensito {
	font-size: 24px;
	font-family: $font-family-serif;
	margin-bottom: $ws/2;

	span {
		cursor: default;
		color: $text-color;
	}
}

.o-link-sorgente-title {
	font-style: italic;
}

.o-link-social {
	display: inline-block;
	width: 50px;
	height: 50px;
	color: #fff;
	text-align: center;
	line-height: 55px;
	font-size: 22px;
	border-radius: 50%;
	border: 1px solid transparent;
	@include transition(all .15s);

	&:hover {
		text-decoration: none;
	}

	&.is-fb {
		background-color: $fb;
		&:hover {
			color: $fb;
			background-color: #fff;
			border-color: $fb;
		}
	}
	&.is-tw {
		background-color: $tw;
		&:hover {
			color: $tw;
			background-color: #fff;
			border-color: $tw;
		}
	}
	&.is-gp {
		background-color: $gp;
		&:hover {
			color: $gp;
			background-color: #fff;
			border-color: $gp;
		}
	}
	&.is-yt {
		background-color: $yt;
		&:hover {
			color: $yt;
			background-color: #fff;
			border-color: $yt;
		}
	}
}
