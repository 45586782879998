.page {

	.main {
		.m-page-header, .o-content-text {
			max-width: 720px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
