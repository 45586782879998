.m-filters-block {
	height: 50px;
	padding: 6px 0;
	text-align: center;
	margin-bottom: $ws*2;
	border-bottom: 1px solid transparent;
	background-color: $gray-light;
	background-image: url('../images/logo-big-transparent.png');
	background-repeat: no-repeat;
	background-position: center -155px;
	background-size: cover;
	@include transition(all .5s);

	// XS ONLY
	@media (max-width: $screen-xs-max) {
		height: 120px;
	}

	// SM - MD - LG
	@media (min-width: $screen-sm-min) {
		padding: 12px 0px;
		background-size: auto;
	}

	// MD - LG
	@media (min-width: $screen-md-min) {
		padding: 9px 0px;
	}
}
