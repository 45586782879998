.m-hamburger {

	&:hover, &:focus {
		background-color: transparent !important;
	}

	.bar-top, .bar-bottom {
		@include transition-transform(.15s);
	}

	.bar-middle {
		@include transition(opacity .15s);
	}

	&.is-open {

		.bar-top {
			@include rotate(45deg);
			@include transform-origin(4px 5px);
		}

		.bar-middle {
			opacity: 0;
		}

		.bar-bottom {
			@include rotate(-45deg);
			@include transform-origin(4px -3px);
		}
	}
}
