// SM - MD - LG
@media (min-width: $screen-sm-min) {
	.wrap {

		&.is-fixed {
			margin-top: 83px;
		}
	}
}

.admin-bar {
	.l-main-header {
		top: 32px;
	}
	.m-filters-block.is-fixed {
		top: 82px;
	}
}