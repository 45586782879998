.form-control {

	&:focus {
		@include box-shadow(none);
	}
}

.asterisk {
	color: $red;
}

.indicates-required {
	text-align: right;
	font-size: $font-size-small;
}