.l-main-header {
	margin-bottom: 0;
	border: 0;

	.o-subtitle-menu {
		color: #eee;
		float: right;
		height: 50px;
		line-height: 49px;
		font-size: $font-size-large;
		text-transform: uppercase;
		font-weight: 300;
	}

	.m-search-form {
		margin-left: $ws;
		float: right;

		input {
			width: 150px !important;
		}
	}

	@media (max-width: $screen-xs-max) {
		background-image: url('../images/logo-mini.png');
		background-repeat: no-repeat;
		background-position: 10px -30px;
		background-size: 160px 29px;
		@include transition(background-position .3s);

		&.has-bg {
			background-position: 10px 10px;
		}

		.nav > li {
			width: 50%;
			float: left;
		}

		.open .dropdown-menu >li > a {
			padding-left: 15px;
		}
	}
}