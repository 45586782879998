@charset "UTF-8";

@font-face {
	font-family: "sitosophia";
	src:url("../fonts/sitosophia.eot");
	src:url("../fonts/sitosophia.eot?#iefix") format("embedded-opentype"),
	url("../fonts/sitosophia.woff") format("woff"),
	url("../fonts/sitosophia.ttf") format("truetype"),
	url("../fonts/sitosophia.svg#sitosophia") format("svg");
	font-weight: normal;
	font-style: normal;

}

[data-icon]:before {
	font-family: "sitosophia" !important;
	content: attr(data-icon);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
	font-family: "sitosophia" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-angle-down:before {
	content: "a";
}
.icon-angle-left:before {
	content: "b";
}
.icon-angle-right:before {
	content: "c";
}
.icon-angle-up:before {
	content: "d";
}
.icon-calendar:before {
	content: "f";
}
.icon-facebook:before {
	content: "g";
}
.icon-google-plus:before {
	content: "h";
}
.icon-mail:before {
	content: "i";
}
.icon-rss:before {
	content: "j";
}
.icon-tag:before {
	content: "k";
}
.icon-comment:before {
	content: "e";
}
.icon-twitter:before {
	content: "l";
}
.icon-youtube:before {
	content: "m";
}
.icon-location:before {
	content: "n";
}
.icon-clock:before {
	content: "o";
}
.icon-external-link:before {
	content: "p";
}
.icon-info:before {
	content: "q";
}
.icon-check-circle-outline:before {
	content: "r";
}
.icon-check-circle-fill:before {
	content: "s";
}
.is-big-green {
	color: $brand-success;
	font-size: 150px;
	line-height: 150px;
	display: block;
	height: 150px;
	text-align: center;
	margin-bottom: $ws;
}
