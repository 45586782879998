.m-logo {
	padding-top: 50px + $ws*2;
	padding-bottom: $ws;
	margin-bottom: $ws*2;
	text-align: center;
	background-color: $gray-lighter;

	.container {
		max-width: 836px;
		margin: 0 auto;
		position: relative;
	}

	a {
		display: inline-block;

		img {
			@extend .img-responsive;
			margin: 0 auto;
		}
	}

	.m-navbar-top {
		font-size: $font-size-large;
		margin-top: $ws;

		.active a {
			text-decoration: underline;
		}

		a {
			color: $text-color;
		}
	}

	// SM - MD - LG
	@media (min-width: $screen-sm-min) {
		padding-bottom: $ws*2;
		margin-bottom: 0;

		.m-navbar-top {
			margin-top: 0;
			position: absolute;
			bottom: -10px;
			left: 15px;
		}
	}

	// MD - LG
	@media (min-width: $screen-md-min) {
		.m-navbar-top {
			left: 25px;
		}
	}
}
